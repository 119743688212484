import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import subDays from 'date-fns/subDays'
import merge from 'lodash/merge'
import { z } from 'zod'

import { usageApi } from '@services/dashboard/entities/usage/usage.api'
import { shedsApi } from '@services/usage/entities/sheds/sheds.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

const tabSchema = z.union([z.literal('overview'), z.literal('material'), z.literal('projects'), z.literal('shed')])

const sortDirectionSchema = z.union([z.literal('asc'), z.literal('desc')])

const sortProjectColumnsNamesSchema = z.union([
  z.literal('name'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('economy'),
  z.literal('co2_target'),
  z.literal('co2_usage.value'),
  z.literal('co2_usage.difference_percentage'),
])

const sortMaterialColumnsNamesSchema = z.union([
  z.literal('title'),
  z.literal('co2'),
  z.literal('fuel'),
  z.literal('idle_hours'),
  z.literal('usage_hours'),
  z.literal('case_number'),
])

const sortShedsColumnsNamesSchema = z.union([
  z.literal('name'),
  z.literal('min_temperature'),
  z.literal('max_temperature'),
  z.literal('temperature_average'),
  z.literal('power_usage_total'),
])

const materialsSearchSchema = z.object({
  searchQuery: z.string().optional(),
  //   owner: z.union([z.literal('gsv'), z.literal('kp'), z.literal('all')]),
  projectId: z.number().optional(),
  startDate: z.string(),
  endDate: z.string(),
  compareVariant: z
    .union([z.literal('preceding'), z.literal('lastYear')])
    .optional()
    .nullable(),
  sort: z
    .object({
      columnName: sortMaterialColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
})

const projectsSearchSchema = z.object({
  searchQuery: z.string().optional(),
  sort: z
    .object({
      columnName: sortProjectColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
})

const shedsSearchSchema = z.object({
  projectId: z.number().optional().nullable(),
  startDate: z.string(),
  endDate: z.string(),
  sort: z
    .object({
      columnName: sortShedsColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
  compareVariant: z
    .union([z.literal('preceding'), z.literal('lastYear')])
    .optional()
    .nullable(),
})

export type TabType = z.infer<typeof tabSchema>

export type MaterialsSearchValues = z.infer<typeof materialsSearchSchema>
export type OverviewSearchValues = Omit<MaterialsSearchValues, 'searchQuery'>
export type ProjectsSearchValues = z.infer<typeof projectsSearchSchema>
export type ShedsSearchValues = z.infer<typeof shedsSearchSchema>

export type SortProjectColumnsNames = z.infer<typeof sortProjectColumnsNamesSchema>
export type SortMaterialColumnsNames = z.infer<typeof sortMaterialColumnsNamesSchema>
export type SortShedsColumnsNames = z.infer<typeof sortShedsColumnsNamesSchema>

interface State {
  activeTab: TabType
  overview: {
    search: OverviewSearchValues
  }
  projects: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search?: ProjectsSearchValues
  }
  materials: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search: MaterialsSearchValues
  }
  sheds: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search: ShedsSearchValues
  }
}

const initialState: State = {
  activeTab: 'overview',
  overview: {
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  projects: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
  },
  materials: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  sheds: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
    search: {
      startDate: subDays(new Date(), 7).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
}

const energyDashboardSlice = createSlice({
  name: 'energyDashboard',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<TabType>) => {
      state.activeTab = action.payload
    },
    /**
     * Merge the Material search values into the state.
     */
    setOverviewSearch: (state, { payload }: PayloadAction<OverviewSearchValues>) => {
      state.overview.search = merge(state.overview.search, payload)
    },

    /**
     * Increment the current page on the Materials tab.
     */
    incrementMaterialsPage: (state) => {
      state.materials.currentPage += 1
    },

    /**
     * Decrement the current page on the Materials tab.
     */
    decrementMaterialsPage: (state) => {
      state.materials.currentPage -= 1
    },

    /**
     * Set the current page on the Materials tab.
     */
    setMaterialsPage: (state, { payload }: PayloadAction<State['materials']['currentPage']>) => {
      state.materials.currentPage = payload
    },

    /**
     * Set the per page value on the Materials tab.
     */
    setMaterialsPerPage: (state, { payload }: PayloadAction<State['materials']['perPage']>) => {
      state.materials.perPage = payload
      state.materials.currentPage = 1
    },

    /**
     * Merge the Material search values into the state and reset the current page.
     */
    setMaterialsSearch: (state, { payload }: PayloadAction<Partial<MaterialsSearchValues>>) => {
      state.materials.search = merge(state.materials.search, payload)
      state.materials.currentPage = initialState.materials.currentPage
    },

    /**
     * Increment the current page on the Projects tab.
     */
    incrementProjectsPage: (state) => {
      state.projects.currentPage += 1
    },

    /**
     * Decrement the current page on the Projects tab.
     */
    decrementProjectsPage: (state) => {
      state.projects.currentPage -= 1
    },
    /**
     * Set the per page value on the Projects tab.
     */
    setProjectsPerPage: (state, { payload }: PayloadAction<State['projects']['perPage']>) => {
      state.projects.perPage = payload
      state.projects.currentPage = 1
    },

    /**
     * Set the current page on the Projects tab.
     */
    setProjectsPage: (state, { payload }: PayloadAction<State['projects']['currentPage']>) => {
      state.projects.currentPage = payload
    },

    /**
     * Merge the Project search values into the state and reset the current page.
     */
    setProjectsSearch: (state, { payload }: PayloadAction<ProjectsSearchValues>) => {
      state.projects.search = merge(state.projects.search, payload)
      state.projects.currentPage = initialState.projects.currentPage
    },

    /**
     * Used to activate skeleton loader on the Overview page when projects are loading for the first time
     */
    setProjectsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.projects.loading = payload
    },

    /**
     * Increment the current page on the Sheds tab.
     */
    incrementShedsPage: (state) => {
      state.sheds.currentPage += 1
    },

    /**
     * Decrement the current page on the Sheds tab.
     */
    decrementShedsPage: (state) => {
      state.sheds.currentPage -= 1
    },

    /**
     * Set the current page on the Sheds tab.
     */
    setShedsPage: (state, { payload }: PayloadAction<State['sheds']['currentPage']>) => {
      state.sheds.currentPage = payload
    },

    /**
     * Set the per page value on the Sheds tab.
     */
    setShedsPerPage: (state, { payload }: PayloadAction<State['sheds']['perPage']>) => {
      state.sheds.perPage = payload
      state.sheds.currentPage = 1
    },

    /**
     * Merge the Sheds search values into the state and reset the current page.
     */
    setShedsSearch: (state, { payload }: PayloadAction<Partial<ShedsSearchValues>>) => {
      state.sheds.search = merge(state.sheds.search, payload)
      // state.materials.currentPage = initialState.materials.currentPage
    },

    /**
     * Reset the current state to the initial state.
     */
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which
       * shouldn't be visible to the user.
       *
       * @see {getUsageMaterials} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(usageApi.endpoints.getUsageMaterials.matchPending, (state, { meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (state.materials.currentPage === meta.arg.originalArgs?.page) {
          state.materials.loading = true
        }
      })
      /**
       * Add lastPage meta to state when the {@link getUsageMaterials} query fulfills
       */
      .addMatcher(usageApi.endpoints.getUsageMaterials.matchFulfilled, (state, { payload }) => {
        state.materials.lastPage = payload.meta?.last_page ?? 1
        state.materials.loading = false
      })
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which
       * shouldn't be visible to the user.
       *
       * @see {getProjects} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(usageApi.endpoints.getUsageProjects.matchPending, (state, { meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (state.projects.currentPage === meta.arg.originalArgs?.page) {
          state.projects.loading = true
        }
      })
      /**
       * Add lastPage meta to state when the {@link getProjects} query fulfills
       */
      .addMatcher(usageApi.endpoints.getUsageProjects.matchFulfilled, (state, { payload }) => {
        state.projects.lastPage = payload.meta?.last_page ?? 1
        state.projects.loading = false
      })
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which
       * shouldn't be visible to the user.
       *
       * @see {getSheds} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(shedsApi.endpoints.getSheds.matchPending, (state, { meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (state.sheds.currentPage === meta.arg.originalArgs?.page) {
          state.sheds.loading = true
        }
      })
      /**
       * Add lastPage meta to state when the {@link getProjects} query fulfills
       */
      .addMatcher(shedsApi.endpoints.getSheds.matchFulfilled, (state, { payload }) => {
        state.sheds.lastPage = payload.meta?.last_page ?? 1
        state.sheds.loading = false
      })
  },
})

// Actions
export const {
  setActiveTab,
  setOverviewSearch,
  incrementMaterialsPage,
  decrementMaterialsPage,
  setMaterialsPage,
  setMaterialsPerPage,
  setMaterialsSearch,
  incrementProjectsPage,
  decrementProjectsPage,
  setProjectsPerPage,
  setProjectsPage,
  setProjectsSearch,
  setProjectsLoading,
  incrementShedsPage,
  decrementShedsPage,
  setShedsPerPage,
  setShedsPage,
  setShedsSearch,
  reset,
} = energyDashboardSlice.actions

/**
 * Hook for selecting the Energy Dashboard state
 * @returns The Energy Dashboard's state
 */
export const useEnergyDashboardState = (): State => {
  return useAppSelector((state: RootState) => state.energyDashboard)
}

export default energyDashboardSlice.reducer
