import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { projectsApi } from '@services/dashboard/entities/project/project.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

interface State {
  currentPage: number
  lastPage?: number
  perPage: number
  loading: 'searching' | 'idle'
  searchQuery?: string
}

const initialState: State = {
  currentPage: 1,
  lastPage: undefined,
  perPage: 10,
  loading: 'idle',
  searchQuery: undefined,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    /**
     * Increment the current page on the overview.
     */
    incrementPage: (state) => {
      state.currentPage += 1
    },

    /**
     * Decrement the current page on the overview.
     */
    decrementPage: (state) => {
      state.currentPage -= 1
    },

    /**
     * Set the current page on the overview.
     */
    setPage: (state, { payload }: PayloadAction<State['currentPage']>) => {
      state.currentPage = payload
    },

    /**
     * Set the per page value on the overview.
     */
    setPerPage: (state, { payload }: PayloadAction<State['perPage']>) => {
      state.perPage = payload
      state.currentPage = 1
    },

    /**
     * Set the search query to the payload value and reset the current page.
     */
    setSearchQuery: (state, { payload }: PayloadAction<State['searchQuery']>) => {
      state.searchQuery = payload
      state.currentPage = 1
    },

    /**
     * Reset the current state to the initial state.
     */
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which
       * shouldn't be visible to the user.
       *
       * @see {getProjects} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(projectsApi.endpoints.getProjects.matchPending, (state, { meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (state.currentPage === meta.arg.originalArgs?.page) {
          state.loading = 'searching'
        }
      })
      /**
       * Add lastPage meta to state when the {@link getProjects} query fulfills
       */
      .addMatcher(projectsApi.endpoints.getProjects.matchFulfilled, (state, { payload }) => {
        state.lastPage = payload.meta?.last_page ?? 1
        state.loading = 'idle'
      })
  },
})

// Actions
export const { incrementPage, decrementPage, setSearchQuery, setPerPage, setPage, reset } =
  projectsSlice.actions

/**
 * Hook for selecting the Projects state
 * @returns The Projects state
 */
export const useProjectsState = (): State => {
  return useAppSelector((state: RootState) => state.projects)
}

export default projectsSlice.reducer
